import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import styles from './TextWithHeading.module.scss'

export default ({ text, heading }) => {
  return (
    <Container className={styles.container} fluid>
      <Row>
        <Col col={{ lg: 7 }} offset={{ lg: 1 }}>
          {heading &&
            <SplitHeading
              tag='h4'
              classNames='hdln--black-blue'
            >
              {heading}
            </SplitHeading>
          }
          <div className='mt-3' dangerouslySetInnerHTML={{ __html: text }} />
        </Col>
      </Row>
    </Container>
  )
}
