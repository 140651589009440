import React from 'react'
import { SplitHeading, ContentCard } from '@cmp/site'
import { Container, Row } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import SmallContentCard from '../../site/SmallContentCard/SmallContentCard';
import { formatDateString } from '@src/utils'

export default ({ heading, link, linkTitle, uppeldishofn }) => {
  return (
    <Container fluid className='pt-md-5 pt-4 pb-md-6'>
      <Row className='align-items-center mb-3 mb-lg-0'>
        <div className='col-8 offset-lg-1'>
          <SplitHeading
            tag='h3'
            classNames='hdln--3 hdln--black-blue'
          >
            {heading}
          </SplitHeading>
        </div>
        <div className='col-lg-2'>
          <Link
            className='link link--standard text-uppercase'
            to={link}>
            {linkTitle}
          </Link>
        </div>
      </Row>
      <Row>
        {uppeldishofn && uppeldishofn.map(({
          date,
          pageTitle,
          featureImage,
          link,
          excerpt
        }, i) => {
          return i === 0 ? <ContentCard
            key={i}
            cardLabel={formatDateString(date)}
            heading={pageTitle}
            image={featureImage}
            link={link}
            text={excerpt}
          />
            : <SmallContentCard
              key={i}
              cardLabel={formatDateString(date)}
              heading={pageTitle}
              image={featureImage}
              link={link}
            />
        }
        )}
      </Row>
    </Container>
  )
}
