import React, { Fragment } from 'react'
import {
  StaffList,
  NewsList,
  PublishedDocumentsList,
  TaxCalculator
} from '@cmp/sections'

export default ({ programName, options, posts }) => {
  return (
    <Fragment>
      {programName === 'stafflist' &&
        <StaffList />
      }
      {programName === 'frettir' &&
        <NewsList posts={posts} />
      }
      {programName === 'published content' &&
        <PublishedDocumentsList />
      }
      {programName === 'tax calculator' &&
        <TaxCalculator />
      }
    </Fragment>
  )
}
