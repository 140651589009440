import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import styles from './Breadcrumbs.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { AngleRight } from '../../../assets/icons'

export default ({ parent, currPage }) => {
  return (
    <Container fluid>
      <Row className='pt-4 mt-2'>
        <Col offset={{ lg: 1 }}
          className={styles.crumbTrail}
        >
          <Link
            className='eyebrows eyebrows--grey'
            to='/'
          >
            Unicef
          </Link>
          <AngleRight />
          <Link
            className='eyebrows eyebrows--grey'
            to={parent.url}
          >
            {parent.name}
          </Link>
          {currPage && (
            <>
            <AngleRight />
            <p
              className='eyebrows eyebrows--black'
            >
              {currPage}
            </p>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}
