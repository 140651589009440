import React from 'react'
import {
  Row,
  Col,
  Container,
  RawHtml,
  FancyShow,
  handleImgixUrl
} from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import styles from './Feature.module.scss'
import Img from 'gatsby-image'

const left = { order: { lg: 1 } }
const right = { order: { lg: 2 }, offset: { xl: 1 } }

const alternateProps = alignment => {
  return alignment === 'left' ? [left, right] : [right, left]
}

export default ({ imageAlignment = 'left', html, image, splitHeading, ...props }) => {
  const [first, second] = alternateProps(imageAlignment)
  const rowAlign = imageAlignment === 'left' ? 'justify-content-start' : 'justify-content-end'
  const fixed = image.url && handleImgixUrl(image.url).gatsbyImageFixed()
  const fluid = image.url && handleImgixUrl(image.url).gatsbyImageFluid()

  return (
    <FancyShow>
      <section>
        <Container fluid className='container-light-grey'>
          <Row className={rowAlign}>
            <Col col={{ xl: 5, lg: 6 }} {...first} className='px-0'>
              <div className='d-block d-lg-none'>
                {fluid && <Img alt={image.alt} fluid={fluid} className={styles.image} />}
              </div>
              {fixed && <Img alt={image.alt} fixed={fixed} className={cn('d-none d-lg-block', styles.image)} />}
            </Col>

            <Col col={{ lg: 6, xl: 5 }} className='pb-4 pt-4 pt-lg-5 mt-2 mt-xl-0' {...second}>
              <FancyShow>
                {splitHeading && (
                  <SplitHeading
                    tag='h2'
                    textCol
                    classNames='hdln--2 hdln--with-bg hdln--blue-bg'
                  >
                    {splitHeading}
                  </SplitHeading>
                )}
                <RawHtml html={html} className='article mt-3' />
              </FancyShow>
            </Col>
          </Row>
        </Container>
      </section>
    </FancyShow>
  )
}
