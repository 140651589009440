import React from 'react'
import styles from './ContentSectionGrey.module.scss'
import { Container } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'

export default ({
  heading,
  heading2,
  text,
  link,
  linkName,
  backgroundColor
}) => {
  return (
    <Container
      fluid
      className={cn(
        { 'container-light-grey': backgroundColor === 'grey' },
        'py-5'
      )}>
      <div className='row'>
        <div className='offset-lg-1 col-md-10'>
          {heading && (
            <SplitHeading
              tag='h2'
              textCol
              classNames='hdln--with-bg hdln--blue-bg pb-3 pb-lg-4'
            >
              {heading}
            </SplitHeading>
          )}
          <div
            className='parag--large'
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <Link
            to={link}
            className='link link--standard mt-3 mt-lg-4 text-uppercase'
          >
            {linkName}
          </Link>
        </div>
      </div>
    </Container>
  )
}
