import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import styles from './PostImage.module.scss'
import Img from 'gatsby-image'
import { handleImgixUrl } from '@kogk/common'

const getFluidImage = (image) => {
  if (!image || !image.url) {
    return null
  }
  return handleImgixUrl(image.url).gatsbyImageFluid()
}
export default ({ image, text }) => {
  const imgDesktop = getFluidImage(image)
  return (
    <div>
      <Img className={styles.image} loading="lazy" fluid={imgDesktop} />
      {text && <span className={styles.imageText}>{text}</span>}
    </div>
  )
}
