import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import styles from './SelectedStaffSection.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { Phone, Envelope, UserFallback } from '../../../assets/icons/'

export default ({ staff, title }) => {
  return (
    <Container fluid>
      <Row className='pt-4'>
        {staff.map((item, key) => {
          const {
            email,
            image,
            imageAdult,
            name,
            phone1,
            phone2,
            title
          } = item
          return (
            <Col
              col={{ xl: 3, lg: 4, md: 6 }}
              className={cn('mb-md-4 mb-2 p-2', styles.card)}
              key={key}
            >
              <StaffCard
                email={email}
                name={name}
                title={title}
                phone1={phone1}
                phone2={phone2}
                image={image}
                imageAdult={imageAdult}
              />
            </Col>
          )
        }
        )}
      </Row>
    </Container>
  )
}

const Image = ({ image, className }) => {
  return (
    <>
      {image
        ? <img className={className} src={image} />
        : <div className={styles.fallback}>
          <UserFallback />
        </div>
      }
    </>
  )
}

const StaffPic = ({
  babyPic,
  adultPic
}) => {
  return (
    <div className={styles.imageWrapper}>
      <Image
        image={adultPic}
        className={styles.adultPic}
      />

      <Image
        image={babyPic}
        className={styles.babyPic}
      />
    </div>
  )
}

const StaffCard = ({
  email,
  name,
  title,
  phone1,
  phone2,
  image,
  imageAdult
}) => {
  const hasPhone = phone1 || phone2
  return <div className={styles.wrapper}>
    <StaffPic
      babyPic={image}
      adultPic={imageAdult}
    />

    <div className={styles.contentContainer}>
      {name &&
        <SplitHeading
          tag='h5'
          textCol
          classNames='hdln--with-bg hdln--blue-bg'
        >
          {name}
        </SplitHeading>
      }
      <p>{title}</p>
      <div className={styles.contactInfo}>
        {email &&
          <div className='pt-3'>
            <Envelope />
            <Link to={`mailto:${email}`}>
              {email}
            </Link>
          </div>
        }
        <div className='mt-1'>
          {hasPhone &&
            <Phone />
          }
          {phone1 &&
            <Link className='mr-2' to={`tel:${phone1}`}>{phone1}</Link>
          }
          {phone2 &&
            <Link to={`tel:${phone2}`}>{phone2}</Link>
          }
        </div>
      </div>
    </div>
  </div>
}
