import React, { useState, useEffect } from 'react'
import { Row, Col, Container, RawHtml, FancyShow } from '@kogk/common'
import { Input } from '@cmp/site'
import { formatCurrency } from '@src/utils'
import styles from './TaxCalculator.module.scss'
import cn from 'classnames'

const MIN_SALARY = 100000
const MAX_SALARY = 2000000
export default ({
  minTaxBracketSalary = '370483',
  maxTaxBracketSalary = '1040106',
  taxBrackets = '31,45% 37,95% 46,25%',
  minRefundSalaryAmount = '160000',
  minRefundDonationAmount = '10000',
  maxRefund = '350000',
}) => {
  const TAX_BRACKETS = taxBrackets.split(' ')
  const [salary, setSalary] = useState(300000)
  const [refund, setRefund] = useState(0)
  const [yearlyDonation, setYearlyDonation] = useState('0')
  const [donation, setDonation] = useState('0')
  const [totalDonations, setTotalDonations] = useState(0)
  const [taxBracket, setTaxBracket] = useState(TAX_BRACKETS[0])
  const calculatePadding = (numStr = '') => 35 + numStr.length * 13

  const onInput = (value, setter) => {
    const numbersOnlyValue = parseInt(
      value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').substring(0, 9)
    )
    if (numbersOnlyValue) {
      setter(numbersOnlyValue.toString())
    } else {
      setter('0')
    }
  }
  useEffect(() => {
    if (salary > parseInt(maxTaxBracketSalary)) {
      setTaxBracket(TAX_BRACKETS[2])
    } else if (salary < parseInt(minTaxBracketSalary)) {
      setTaxBracket(TAX_BRACKETS[0])
    } else {
      setTaxBracket(TAX_BRACKETS[1])
    }
  }, [salary])
  useEffect(() => {
    const total = parseInt(donation) + parseInt(yearlyDonation) * 12
    setTotalDonations(total)
    let amount = 0
    // Minimum values to be eligible for refund
    if (total >= parseInt(minRefundDonationAmount) && salary >= parseInt(minRefundSalaryAmount)) {
      // Convert tax bracket to e.g. 37.45% -> 0.3745 to calculate refund
      amount =
        (parseFloat(taxBracket.replace('%', '').replace(',', '.')) / 100) *
        total
      if (amount > parseInt(maxRefund)) {
        amount = parseInt(maxRefund)
      }
    }
    setRefund(amount)
  }, [donation, yearlyDonation, taxBracket, salary])
  return (
    <FancyShow className='m-5'>
      <section>
        <Container fluid>
          <Row>
            <Col col={{ lg: 5 }}>
              <p className='eyebrows eyebrows--black'>Reiknivél</p>
              <div className='mt-4'>
                <span className={styles.calculatorLabel}>
                  Mánaðarlegar tekjur:
                </span>{' '}
                <span className={styles.calculatorCurrency}>
                  {formatCurrency(parseInt(salary))} kr
                </span>
                <div className='w-100'>
                  <input
                    type='range'
                    min={MIN_SALARY}
                    max={MAX_SALARY}
                    step='1000'
                    value={salary}
                    className={styles.slider}
                    onChange={(e) => onInput(e.target.value, setSalary)}
                  />
                </div>
              </div>
              <div>
                <div className={cn('mt-4 w-100 position-relative')}>
                  <Input
                    id='yearly-donation'
                    name='yearly-donation'
                    value={formatCurrency(parseInt(yearlyDonation))}
                    type='text'
                    label='Framlög á mánuði'
                    className='container-light-grey'
                    onChange={(e) => onInput(e.target.value, setYearlyDonation)}
                  />
                  <span
                    className={cn(styles.krLabel)}
                    style={{ left: `${calculatePadding(yearlyDonation)}px` }}
                  >
                    kr.
                  </span>
                </div>
              </div>
              <div>
                <div className='mt-4 w-100 position-relative'>
                  <Input
                    id='single-donation'
                    name='single-donation'
                    value={formatCurrency(parseInt(donation))}
                    label='Stök framlög'
                    type='text'
                    className='container-light-grey'
                    onChange={(e) => onInput(e.target.value, setDonation)}
                  />
                  <span
                    className={cn(styles.krLabel)}
                    style={{ left: `${calculatePadding(donation)}px` }}
                  >
                    kr.
                  </span>
                </div>
              </div>
              <div className='mt-4'>
                <p className='eyebrows eyebrows--black'>Endurgreiðsla</p>
                <p className={styles.calculatorRefund}>
                  {formatCurrency(refund)} KR.
                </p>
              </div>
            </Col>
            <Col col={{ lg: 1 }} />

            <Col col={{ lg: 5 }}>
              <div className='container-light-grey'>
                <div className='py-3 pl-4'>
                  <p className='parag--form-text'>
                    Skattþrep miðað við mánaðarlegar tekjur
                  </p>
                  <p className='parag--large font-weight-bold'>{taxBracket}</p>
                  <p className='parag--form-text mt-3'>Heildarframlög á ári</p>
                  <p className='parag--large font-weight-bold'>
                    {formatCurrency(totalDonations)} KR.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </FancyShow>
  )
}
