import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import styles from './TextwButton.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { useLocation } from "@reach/router"
import { rememberLocation } from '@src/context/Authentication'
import { API_URL } from '@src/utils'

export default ({
  buttonLink,
  text,
  buttonText,
}) => {
  const isLogin = !buttonLink
  const { protocol, host } = useLocation()
  const back = `${protocol}//${host}`
  buttonLink =
    buttonLink || `${API_URL}/api/login?host=${encodeURIComponent(back)}`

  const link = isLogin ? (
    <a
      href={buttonLink}
      className='button button--normal yellow-link w-100'
      onClick={rememberLocation}
    >
      {buttonText}
    </a>
  ) : (
    <Link to={buttonLink} className='button button--normal yellow-link w-100'>
      {buttonText}
    </Link>
  )

  return (
    <Container className={styles.container}>
      <Row>
        <Col offset={{ md: 1 }} col={{ md: 10 }}>
          {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        </Col>
        <Col offset={{ md: 1 }} col={{ md: 4 }} className='pt-3'>
          {buttonText && link}
        </Col>
      </Row>
    </Container>
  )
}
