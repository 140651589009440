import React, { useState, Fragment } from 'react'
import { Field, Form, Formik } from 'formik'
import { Col, Container, Row } from '@kogk/common'
import { mapValues } from 'lodash'
import { useAsyncFn } from 'react-use'
import cn from 'classnames'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

import { Checkbox, Input, Spinner } from '@cmp/site'
import { API_URL } from '@src/utils'

import * as fields from './fields'
import styles from './PostlistForm.module.scss'

const PostlistForm = ({ onSubmit, error, isSubmitting }) => {
  const { t } = useLanguage()

  const rendered = (error) => {
    return (
      <div className={cn(styles.error, 'mt-2')}>
        {error ?? t(`Postlist Strings|Error Message`)}
      </div>
    )
  }

  return (
    <Fragment>
      <Formik
        initialValues={fields.initialValues()}
        validate={(values) => fields.validateAll(values)}
        onSubmit={(values) => onSubmit(mapValues(values, fields.cleanField))}
      >
        {({ values }) => {
          return (
            <Form>
              <div className='eyebrows eyebrows--black'>
                {t(`Postlist Strings|Contact Info Heading`)}
              </div>
              <MainForm values={values} />
              <button
                type='submit'
                className={cn(styles.submit, 'mb-4 mt-4 button--normal')}
              >
                {isSubmitting ? (
                  <Spinner />
                ) : (
                  t(`Postlist Strings|postlist button`)
                )}
              </button>

              {error && rendered(error)}
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

const MainForm = ({}) => {
  return (
    <>
      <Field component={InputField} name='name' label='Nafn' />
      <Field component={InputField} name='email' label='Netfang' />

      <div className={cn(styles.row, 'pb-4')}>
        <Field component={TermsField} name='terms' />
      </div>
    </>
  )
}

const InputField = ({ field, form, ...props }) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <div className={styles.row}>
      <Input {...field} {...props} hasError={Boolean(error)} />
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  )
}

const TermsField = ({ field, form, ...props }) => {
  return (
    <div>
      <Checkbox {...field} {...props}>
        Ég hef kynnt mér og samþykki
      </Checkbox>{' '}
      <a href='/personuvernd' target='_blank' className='link link--standard'>
        skilmála UNICEF
      </a>
      {Boolean(form.touched.terms && form.errors.terms) && (
        <div className={cn('px-0', styles.error)}>{form.errors.terms}</div>
      )}
    </div>
  )
}

const Layout = ({ introText = '', children }) => {
  return (
    <Fragment>
      <Container fluid>
        {Boolean(introText) && (
          <Row className='pt-5'>
            <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
              <p className='parag--large'>{introText}</p>
            </Col>
          </Row>
        )}
        <Row className='pt-4 mb-2 mb-md-0 pb-4 pb-md-6'>
          <Col col={{ lg: 4 }} offset={{ lg: 1 }}>
            {children}
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
export default ({ successMessage, zenterAudienceId, introText }) => {
  const [successData, setSuccessData] = useState()
  const [submit, onSubmit] = useAsyncFn(async formData => {
    const r = await fetch(`${API_URL}/api/postlist/subscribe`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
          ...formData,
          audienceId: zenterAudienceId
        })
    })
    const data = await r.json()
    if(r.ok) {
      setSuccessData(data)
    }
    return data
  }, [])
  const error = submit.value?.error
  const isSubmitting = submit.loading || Boolean(submit.value?.location)
  if (successData) {
    return (
      <Layout
        introText={
          successMessage ?? 'Takk fyrir að skrá þig á póstlista Unicef'
        }
      ></Layout>
    )
  }
  return (
    <Layout introText={introText}>
      <PostlistForm
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        error={error}
      />
    </Layout>
  )
}
