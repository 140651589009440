import React from 'react'
import {
  Row,
  Container
} from '@kogk/common'
import { ContentCard } from '@cmp/site'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
query documentQuery {
  allPrismicDocumentItem {
    edges {
      node {
        data {
          excerpt {
            text
          }
          title {
            text
          }
          link {
            url
          }
          image {
            thumbnails {
              thumbnail {
                url
              }
              smaller {
                url
              }
            }
            url
          }
        }
      }
    }
  }
}
`

export default () => {
  const data = useStaticQuery(query)

  return (
    <Container fluid>
      <Row>
        {data.allPrismicDocumentItem.edges.map((item, key) => {
          const {
            title: { text: title },
            image,
            link: { url: link },
            excerpt: { text: excerpt }
          } = item.node.data

          return <ContentCard
            key={key}
            heading={title}
            image={image}
            link={link}
            text={excerpt}
            isDocumentCard
          />
        })}
      </Row>
    </Container>
  )
}
