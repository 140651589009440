import React from 'react'
import { Container, Row } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import styles from './ContactInfoSection.module.scss'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import email from '../../../assets/icons/email.svg'
import phone from '../../../assets/icons/phone.svg'
import house from '../../../assets/icons/house.svg'

export default ({ heading }) => {
  const { t } = useLanguage()

  return (
    <Container fluid className={cn('container-blue', styles.container)}>
      <Row className='justify-content-center'>
        <div className='text-center'>
          <div className='pb-1 pb-md-3'>
            {heading && (
              <SplitHeading
                tag='h2'
                textCenter
                textCol
                classNames='hdln--2 hdln--with-bg hdln--white-bg'
              >
                {heading}
              </SplitHeading>
            )}
          </div>
        </div>
      </Row>
      <Row className='justify-content-between justify-content-lg-center'>
        <div className='col-md-3 text-center pt-2 pt-md-0'>
          <div className={cn(styles.icon, 'pb-2 pb-md-3')}>
            <img src={email} />
          </div>
          <div className='d-flex justify-content-center'>
            <p className='eyebrows eyebrows--black'>
              {t(`contact info|email label`)}
            </p>
          </div>
          <div className='mt-2 mt-md-3'>
            <span className='link__wrapper'>
              <Link
                to={`mailto:${t(`contact info|email`)}`}
                className='link link--big-on-blue'>
                {t(`contact info|email`)}
              </Link>
            </span>
          </div>
        </div>
        <div className='col-md-3 text-center pt-3 pt-md-0'>
          <div className={cn(styles.icon, 'pb-2 pb-md-3')}>
            <img src={phone} />
          </div>
          <div className='d-flex justify-content-center'>
            <p className='eyebrows eyebrows--black'>
              {t(`contact info|phone label`)}
            </p>
          </div>
          <div className='mt-2 mt-md-3'>
            <span className='link__wrapper'>
              <Link
                className='link link--big-on-blue'
                to={`tel:${t(`contact info|phone`)}`}>
                {t(`contact info|phone`)}
              </Link>
            </span>
          </div>
        </div>
        <div className='col-md-3 text-center pt-3 pt-md-0'>
          <div className={cn(styles.icon, 'pb-2 pb-md-3')}>
            <img src={house} />
          </div>
          <div className='d-flex justify-content-center'>
            <p className='eyebrows eyebrows--black'>
              {t(`contact info|address label`)}
            </p>
          </div>
          <div className='mt-2 mt-md-3'>
            <span className='link__wrapper'>
              <Link
                to={t(`contact info|address link`)}
                className='link link--big-on-blue'>
                {t(`contact info|address`)}
              </Link>
              <p className='parag--small pt-2'>{t(`contact info|open hours`)}</p>
            </span>
          </div>
        </div>
      </Row>
    </Container>
  )
}
