import { trim, mapValues } from 'lodash'

const name = {
  clean: trim,
}

const email = {
  clean: trim,
  validate: (s) => !/^.+@.+\..+$/.test(s) && 'Þetta er ekki gilt netfang',
}

const terms = {
  initialValue: false,
  validate: (checked) => !checked && 'Þú átt eftir að samþykkja skilmála',
}

export const allFields = {
  name,
  email,
  terms,
}

export const initialValues = () => {
  return mapValues(allFields, (f) =>
    f.initialValue == null ? '' : f.initialValue
  )
}

export const cleanField = (value, name) => {
  if (allFields[name].clean) {
    value = allFields[name].clean(value)
  }
  return value
}

const fieldsRequired = () => {
  return [
    'name',
    'email',
    'terms',
  ]
}

export const validateAll = (values) => {
  const requiredFields = fieldsRequired()

  const errors = {}
  requiredFields.forEach((name) => {
    const value = cleanField(values[name], name)
    const validator = allFields[name].validate
    const error =
      value === ''
        ? 'Þessi reitur má ekki vera tómur'
        : validator && validator(value)

    if (error) {
      errors[name] = error
    }
  })

  return errors
}
