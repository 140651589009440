import React from 'react'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import styles from './LatestNewsSection.module.scss'
import { SplitHeading, ContentCard } from '@cmp/site'
import { Container, Row } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { useGlobalData } from '@src/data/global-data'
import SmallContentCard from '../../site/SmallContentCard/SmallContentCard'
import { formatDateString } from '@src/utils'
import fallback from '../../../assets/logos/unicef-white-stacked.png'

export default ({ heading, link, newsItems }) => {
  const { t } = useLanguage()
  const { latestNews } = useGlobalData()
  newsItems = newsItems ?? latestNews

  return (
    <Container fluid className='pt-md-5 pt-4 pb-md-6'>
      <Row className='align-items-center mb-3 mb-lg-0'>
        <div className='col-8 offset-lg-1 mb-3'>
          <SplitHeading tag='h3' classNames='hdln--3 hdln--black-blue'>
            {heading}
          </SplitHeading>
        </div>
        {link && (
          <div className='col-lg-2'>
            <Link className='link link--standard text-uppercase' to={link}>
              {t(`news|See all label`)}
            </Link>
          </div>
        )}
      </Row>
      <Row>
        {newsItems &&
          newsItems.map(
            ({ date, pageTitle, featureImage, url, excerpt }, i) => {
              return i === 0 ? (
                <ContentCard
                  key={i}
                  cardLabel={formatDateString(date)}
                  heading={pageTitle}
                  image={featureImage}
                  link={url}
                  text={excerpt}
                />
              ) : (
                <SmallContentCard
                  key={i}
                  cardLabel={formatDateString(date)}
                  heading={pageTitle}
                  image={featureImage}
                  link={url}
                />
              )
            }
          )}
      </Row>
    </Container>
  )
}
