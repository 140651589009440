import React, { useState, Fragment } from 'react'
import styles from './FAQs.module.scss'
import { Container, Row, Col } from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import { Plus, Minus } from '../../../assets/icons/'

export default ({ heading, faqs }) => {
  const [openItem, setOpenItem] = useState({})
  const blue = '#00AEEF'
  const grey = '#1E1E1E'

  const toggleCollapse = (item) => {
    openItem === item ? setOpenItem(null) : setOpenItem(item)
  }

  return (
    <Container fluid className="py-5 py-md-6">
      <Row>
        <Col col={12} offset={{ md: 1 }}>
          {heading && (
            <SplitHeading tag="h2" classNames="hdln--2 hdln--black-blue">
              {heading}
            </SplitHeading>
          )}
        </Col>
      </Row>
      <Row>
        {faqs.map((item, key) => {
          const {
            answer: { html: answer },
            question: { text: question },
          } = item

          return (
            <Fragment key={key}>
              <Col col={{ md: 8 }} offset={{ md: 1 }}>
                <div className={styles.questionRow}>
                  <button
                    className="w-100 d-flex"
                    onClick={() => toggleCollapse(item)}
                  >
                    {openItem === item ? (
                      <Minus fill={blue} />
                    ) : (
                      <Plus fill={grey} />
                    )}
                    <p
                      className={cn('parag mb-0', styles.question, {
                        [styles.bold]: openItem === item,
                      })}
                    >
                      {question}
                    </p>
                  </button>
                  <div
                    className={cn(styles.answer, {
                      [styles.collapsed]: openItem !== item,
                    })}
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </div>
              </Col>
              <Col
                className="border--small border--light-grey"
                col={{ md: 10 }}
                offset={{ md: 1 }}
              />
            </Fragment>
          )
        })}
      </Row>
    </Container>
  )
}
