import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import styles from './BannerImage.module.scss'
import cn from 'classnames'

export default ({ text, image, label, className }) => {
  return (
    <>
      {label
        ?
        <div className={className}>
          <img className={styles.image} src={image} />
          {text &&
            <span className={styles.imageText}>{text}</span>
          }
          </div>
        :
        <Container fluid>
          <Row>
            <Col col={{ lg: 7 }} offset={{ lg: 1 }}>
              <div className='py-3'>
                <img className={styles.image} src={image} />
                {text &&
                  <span className={styles.imageText}>{text}</span>
                }
              </div>
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}
