import React, { useState } from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { ContentCard } from '@cmp/site'
import SmallContentCard from '../../site/SmallContentCard/SmallContentCard'
import cn from 'classnames'
import styles from './NewsList.module.scss'
import { formatDateString } from '@src/utils'

export default ({ posts }) => {
  const [noItems, setNoItems] = useState(10)

  return (
    <Container fluid>
      <Row>
        {posts.slice(0, noItems).map(({
          pageTitle,
          excerpt,
          featureImage,
          date,
          url,
          tag
        }, key) => {
          return key < 2 ? <ContentCard
            key={key}
            cardLabel={formatDateString(date)}
            heading={pageTitle}
            image={featureImage}
            link={url}
            text={excerpt}
          />
            : <SmallContentCard
              cardLabel={formatDateString(date)}
              heading={pageTitle}
              image={featureImage}
              link={url}
            />
        })}
      </Row>
      {posts.length > noItems &&
        <Row>
          <Col
            col={12}
            className='d-flex justify-content-center '
          >
            <button
              onClick={() => setNoItems(noItems + 8)}
              className={cn(
                styles.loadMore,
                'button button--ghost text-uppercase'
              )}>
              Sækja fleiri fréttir
            </button>
          </Col>
        </Row>
      }
    </Container>
  )
}
