import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { ContentCard } from '@cmp/site'
import cn from 'classnames'
import styles from './DocumentSection.module.scss'

export default ({ text, cards }) => {
  return (
    <Container fluid>
      <Row>
        <Col col={{ lg: 10 }} offset={{ lg: 1 }} className='pt-4'>
          <p className='parag--large'>{text}</p>
        </Col>
        {cards &&
          <>
            {cards.map((item, key) => {
              const {
                image,
                link,
                linkTitle,
                text,
                title
              } = item

              return <ContentCard
                key={key}
                heading={title}
                image={image}
                link={link}
                text={text}
                linkTitle={linkTitle}
                isDocumentCard
              />
            })}
          </>
        }
      </Row>
    </Container>
  )
}
