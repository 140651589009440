import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { SplitHeading, SmallContentCard, ContentCard } from '@cmp/site'
import cn from 'classnames'
import styles from './FeaturedArticles.module.scss'

export default ({ title, articles, firstTwoBigger }) => {

  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col offset={{ md: 1 }}>
          <SplitHeading tag='h2' classNames='hdln--3 hdln--black-blue'>
            {title}
          </SplitHeading>
        </Col>
      </Row>
      <Row>
        <Cards articles={articles} firstTwoBigger={firstTwoBigger} />
      </Row>
    </Container>
  )
}

const Cards = ({ articles, firstTwoBigger }) => {
  return articles.map((article, key) => {
    const Cmp = key <= 1 && firstTwoBigger ? ContentCard : SmallContentCard
    return (
      <Cmp
        key={key}
        tag={article.data.post_type}
        heading={article.data.page_title.text}
        image={article.data.feature_image}
        link={article.url}
        text={article.data.excerpt.text}
      />
    )
  })
}
