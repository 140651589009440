import React from 'react'
import { RawHtml, FancyShow, handleImgixUrl } from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import Img from 'gatsby-image'
import style from './Hero.module.scss'

const TEXT_CONTAINER_CLASSES = `
    d-flex
    align-items-center
    justify-content-center
    position-absolute
    w-100
    h-100
  `

const getFluidImage = image => {
  if (!image || !image.url) {
    return null
  }

  return handleImgixUrl(image.url).gatsbyImageFluid()
}

export default ({ caption, image }) => {
  const imgDesktop = getFluidImage(image)
  const imgMobile = getFluidImage(image.thumbnails.phone)

  return (
    <SliceWrapper shouldMove={false} fullBleed>
      <div className={`position-relative pb-3 ${style.container}`}>
        {imgDesktop && (
          <Img
            loading='lazy'
            className='d-none d-sm-block'
            fluid={imgDesktop}
            alt={image.alt}
          />
        )}
        {imgMobile && (
          <Img
            loading='lazy'
            className='d-sm-none'
            fluid={imgMobile}
            aria-hidden='true'
            alt={image.alt}
          />
        )}

        <div
          className={TEXT_CONTAINER_CLASSES}
          style={{
            textShadow: '0 2px 40px rgba(0, 0, 0, 0.5)',
            top: 0,
            left: 0
          }}
        >
          <FancyShow>
            <RawHtml
              className='text-center px-2'
              style={{
                color: 'white'
              }}
            >
              {caption}
            </RawHtml>
          </FancyShow>
        </div>
      </div>
    </SliceWrapper>
  )
}
