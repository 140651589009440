import React from 'react'
import styles from './ThreeImageSection.module.scss'
import { Container, Row, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'

export default ({ heading, link, postcards, label, linkName }) => {
  const [firstPostCard, secondPostCard, thirdPostCard] = postcards

  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col className='d-inline d-lg-none col-lg-10 offset-lg-1 mb-3 pb-2'>
          <div className={styles.sectionHeadings}>
            <SplitHeading textCol tag='h3' classNames='hdln--black-blue'>
              {heading}
            </SplitHeading>
          </div>
          <Link to={link} className='link link--standard text-uppercase pb-4'>
            {linkName}
          </Link>
        </Col>
        {firstPostCard && (
          <Col col={{ lg: 6 }} className='mb-2 mb-lg-0'>
            <PostCard
              postcard={firstPostCard}
              isLarge
              image={firstPostCard.image.url}
              tag='h4'
            />
          </Col>
        )}
        <Col
          col={{ lg: 6 }}
          className='d-flex flex-column px-2 justify-content-between'
        >
          <Row>
            <Col className='col-md-10 offset-lg-1 d-none d-lg-block pt-2 pt-lg-4 pb-md-5'>
              <div className={styles.sectionHeadings}>
                <SplitHeading textCol tag='h3' classNames='hdln--black-blue'>
                  {heading}
                </SplitHeading>
              </div>
              <Link to={link} className='link link--standard text-uppercase'>
                {linkName}
              </Link>
            </Col>
          </Row>
          <Row className='justify-content-between'>
            {secondPostCard && (
              <Col col={6} className='pr-1 pr-lg-2'>
                <PostCard
                  tag='h5'
                  postcard={secondPostCard}
                  image={secondPostCard.image.thumbnails.smaller.url}
                />
              </Col>
            )}
            <Col col={6} className='pl-1 pl-lg-2'>
              {thirdPostCard && (
                <PostCard
                  tag='h5'
                  postcard={thirdPostCard}
                  image={thirdPostCard.image.thumbnails.smaller.url}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

const PostCard = ({ postcard, isLarge, image, tag }) => {
  return (
    <Link to={postcard.link.url}>
      <div className={cn(styles.imageWrapper, { [styles.large]: isLarge })}>
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className={styles.postcardHeading}>
          <SplitHeading
            tag={tag}
            textCol
            classNames='hdln--with-bg hdln--blue-bg'
          >
            {postcard.heading.text}
          </SplitHeading>
        </div>
      </div>
    </Link>
  )
}
