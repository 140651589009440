import React from 'react'

export default ({ text, name }) => {
  return (
    <blockquote className='py-3'>
      <p>{text}</p>
      <strong>{name}</strong>
    </blockquote>
  )
}
