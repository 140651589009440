import React from 'react'
import styles from './BlueTextSection.module.scss'
import { Container, Row, Col } from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'

export default ({ text, isTopHero }) => {
  return (
    <>
      {isTopHero ? (
        <TopHeroText text={text} />
      ) : (
        <Container fluid className={styles.container}>
          <Row>
            <BigQuote text={text} />
          </Row>
        </Container>
      )}
    </>
  )
}

const TopHeroText = ({ text }) => {
  return (
    <Row className={styles.topHero}>
      <Col className='offset-lg-1'>
        {text && (
          <SplitHeading
            tag='h2'
            textCol
            classNames='d-block hdln--2 hdln--with-bg hdln--white-bg hdln--blue pt-md-5 mt-4 ml-2'
          >
            {text}
          </SplitHeading>
        )}
      </Col>
    </Row>
  )
}

const BigQuote = ({ text }) => {
  return (
    <Col className={cn(styles.quoteSection, 'col-md-10 offset-md-1')}>
      <SplitHeading tag='h3' textCenter classNames='hdln--3 hdln--white-black'>
        {text}
      </SplitHeading>
    </Col>
  )
}
