import React from 'react'
import { RawHtml } from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'

export default ({ html, heading, className }) => (
  <div className={cn('pb-3 pt-3', className)}>
    {heading &&
      <div className='mb-2'>
        <SplitHeading
          tag='h4'
          classNames='hdln--black-blue'
        >
          {heading}
        </SplitHeading>
      </div>
    }
    <RawHtml el='div' className='article'>
      {html}
    </RawHtml>
  </div>
)
