import React, { useContext } from 'react'
import styles from './TopHero.module.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { BlueTextSection, Breadcrumbs } from '@cmp/sections'
import { Container, Row, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { SplitHeading, WidgetWrapper } from '@cmp/site'
import { PageDataContext } from '@src/context/PageDataContext'

export default ({
  imageArr,
  heading,
  breadcrumb,
  hasCrumbs
}) => {
  const { pageTitle } = useContext(PageDataContext)

  return (
    <Container
      fluid
      className='p-0'
    >
      {heading !== "" &&
        <BlueTextSection isTopHero text={heading} />
      }

      {imageArr.length >= 2 &&
        <MultiImages imageArr={imageArr} />
      }

      {imageArr.length === 1 &&
        <SingleImage imageArr={imageArr} />
      }
      {hasCrumbs &&
        <Breadcrumbs parent={breadcrumb} currPage={pageTitle} />
      }
    </Container>
  )
}

const SingleImage = ({ imageArr }) => {
  const popped = imageArr.pop()
  return (
    <>
      <div className={styles.imageWrapper}>
        <Row
          className={styles.imageBackground}
          style={{ backgroundImage: `url(${popped.image})` }}
        >
          <div className='offset-lg-1'>
          {popped.heading &&
            <SplitHeading
              tag='h1'
              textCol
              classNames='hdln--1 hdln--with-bg hdln--blue-bg mb-3 ml-3 ml-lg-0'
            >
              {popped.heading}
            </SplitHeading>
          }
            {popped.link &&
              <Link
                to={popped.link}
                className='yellow-link'
              >
                {popped.linkName}
              </Link>
            }
          </div>
          {popped.styrktarVerkefni &&
            <Col
              className='p-0 mb-4 d-none d-xl-inline'
              col={{ lg: 4 }}
              offset={{ xxl: 1, lg: popped.styrktarVerkefni?.data?.amounts?.length > 0 ? 1 : 0 }}
            >
              <WidgetWrapper data={popped.styrktarVerkefni} />
            </Col>
          }
        </Row>
      </div>
      {popped.styrktarVerkefni &&
        <Col className='p-0 mb-4 d-xl-none d-inline'>
          <WidgetWrapper data={popped.styrktarVerkefni} />
        </Col>
      }
    </>
  )
}

const MultiImages = ({ imageArr }) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      interval={4000}
      infiniteLoop
    >
      {imageArr.map((slide, key) => {
        return (
          <div className={styles.imageWrapper} key={key}>

            <Row
              className={styles.imageBackground}
              style={{
                backgroundImage: `url(${slide.image})`
              }}
            >
              <div className='legend'>
                <div className='hdln--1 hdln--with-bg hdln--blue-bg mb-3'>
                  <SplitHeading tag='h1' textCol>
                    {slide.heading}
                  </SplitHeading>
                </div>
                {slide.link &&
                  <Link
                    to={slide.link}
                    className='yellow-link'
                  >
                    {slide.linkName}
                  </Link>
                }
              </div>
            </Row>
          </div>
        )
      })}
    </Carousel>
  )
}
