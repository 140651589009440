import React from 'react'
import { Link } from '@kogk/gatsby-theme-base'
import { SplitHeading } from '@cmp/site'
import { formatDateString } from '@src/utils'
import cn from 'classnames'
import styles from './LatestNews.module.scss'
import { useGlobalData } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default () => {
  const { latestNews } = useGlobalData()
  const { t } = useLanguage()

  const buttonClasses = cn(
    styles.button,
    'd-flex button button--ghost w-100 align-items-center justify-content-center mb-5',
  )

  return (
    <span className="">
      <div className="pt-3">
        <SplitHeading tag="h5" classNames="hdln--5 hdln--black-blue">
          {t(`news|More news label`)}
        </SplitHeading>
      </div>
      {latestNews &&
        latestNews.map(
          ({ excerpt, date, featureImage, pageTitle, url }, index) => {
            return (
              <NewsCard
                key={index}
                date={date}
                heading={pageTitle}
                link={url}
              />
            )
          },
        )}
      <Link to={t(`news|all news link`)} className={buttonClasses}>
        {t(`news|go to news overview label`)}
      </Link>
    </span>
  )
}

const NewsCard = ({ link, date, heading }) => {
  const { t } = useLanguage()

  return (
    <div className={cn(styles.newsCard, 'w-100 mb-3')}>
      <span>
        <p className="parag--date-text">{formatDateString(date)}</p>
        <h6 className="hdln--herosub">{heading}</h6>
      </span>
      <Link to={link} className="link link--standard">
        {t(`news|read more label`)}
      </Link>
    </div>
  )
}
